
footer{
    background-color: $grey3;
    // padding: 60px 0;
    .logo{
        margin-bottom: 15px;
        display: inline-block;
    }
    .social{
        ul{
            display: flex;
            gap: 10px;
            padding: 0;
            margin: 0;
            a{
                width: 40px;
                height: 40px;
                background: $gradient;
                line-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                svg{
                    stroke: $white;
                    stroke-width: 1.5px;
                    height: 20px;
                }
                &:hover{
                    background: $gradient2;
                }
            }
        }
    }
    h5{
        font-size: 16px;
        font-weight: 700 !important;
        margin-bottom: 15px;
    }
    p{
        font-size: 14px;
    }
    ul{
        li{
            a{
                color: $dark2;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
    .container{
        &:first-child{
            padding-top: 60px;
            padding-bottom: 60px;
            border-bottom: 1px solid $grey2;
        }
        &:last-child{
            padding-top: 20px;
            padding-bottom: 20px;
            p{
                font-weight: 600;
                margin: 0;
            }
        }
    }
}