// Variable overrides first
$gradient: var(--Gradient, linear-gradient(225deg, #3A2A7E 0%, #741F78 100%));
$gradient2: var(--Gradient, linear-gradient(225deg, #741F78 0%, #741F78 100%));

$primary: #741F78 !default;

$secondary: #3A2A7E !default;
$dark: #262628 !default;
$dark2: #4F5E64 !default;
$grey1: #B9BACD !default;
$grey2: #DADBE4 !default;
$grey3: #F1F2F7 !default;
$white: #ffffff !default;
$green: #40C375 !default;
$red: #dc3545 !default;
$orange: #FE7409 !default;

$border: thin solid #DADBE4 !important;
$enable-shadows: true;
$prefix: "mo-"; 
 
