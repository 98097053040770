
@import "variable.scss";
@import "mixins";

.banner{
    .banner-box{
        background-color: $grey3; 
        text-align: center;
        padding: 40px;
        @include radius8;
        position: relative;
        h1{
            font-size: 35px;
            font-weight: 700;
            color: $dark;
        }
        h5{
            font-size: 18px; 
            color: $dark;
        }
        form{
            background: #fff;
            box-shadow: 0 18px 40px rgba(25,15,9,.1);
            display: inline-block;
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 30px;
            // gap: 10px;
            @include radius8;
            input.form-control{
                border: none !important;
                box-shadow: none;
                &.btn{
                    height: 50px;
                }
            }
            .select2{
                border: 0px solid rgba(26, 15, 9, 0.1);
                border-radius: 0px;
                padding: 10px 20px 10px 30px;
                display: inline-block;
                width: 100% !important;
                // margin-right: 10px;
                position: relative;
                max-width: 230px;
            }
            .industry + span{
                position: relative;
                font-size: 15px !important;
                &::after{
                    content: '';
                    width: 2px;
                    height: 30px;
                    background-color: $grey2;
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 10px;
                }
                &::before{
                    content: "";
                    background: url(../img/suitcase.svg) no-repeat;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 11px;
                    top: 15px;
                }
            } 
            .location + span{
                position: relative;
                font-size: 15px !important;
                &::after{
                    content: '';
                    width: 2px;
                    height: 30px;
                    background-color: $grey2;
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 10px;
                }
                &::before{
                    content: "";
                    background: url(../img/icon-location-2.svg) no-repeat;
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 11px;
                    top: 15px;
                }
            }
            .keyword{
                background: url(../img/keyword.svg) no-repeat left 10px center;
                padding: 10px 20px 10px 34px;
                font-size: 15px !important; 
            }
        }
        .logos{
            display: flex;
            gap: 10px;
            justify-content: center;
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 140px;
                height: 70px;
                background-color: $white;
                padding: 10px;
                @include radius8;
                img{
                    max-width: 110px;
                    max-height: 50px;
                }
            }
        }
        &::before{
            content: '';
            width: 330px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(../img/bnnr-pattern1.png);
            background-size: cover;
            z-index: 0;
            opacity: .5;
        }
        &::after{
            content: '';
            width: 330px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-image: url(../img/bnnr-pattern2.png);
            background-size: cover;
            z-index: 0;
            opacity: .5;
        }
        .row{
            position: relative;
            z-index: 9;    
        }
    }

    // select
    .select2-selection{
        height: 25px !important;
        border: none !important;
        position: relative;
        @include radius8;
        span{
            height: 25px !important;
            line-height: 25px !important;
            text-align: left;
            color: $dark2 !important;
        }
        .select2-selection__rendered{
            padding-left: 5px !important;
        }
        .select2-selection__arrow{
            background: url(../img/arrow-down.svg) no-repeat right 5px center;
            right: -10px !important;
            top: 2px !important;
            b{
                display: none;
            }
        } 
    }
    input{
        outline: none;
        border: thin solid $grey2 !important;
        // height: 40px;
        padding-left: 15px;
        @include radius4;
        &:hover:not(:focus) {
            background-color: transparent !important;
        }
    }
    .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
        background: $grey2;
        color: $dark2;
    }
    .select2-dropdown{
        border: 1px solid $grey3; 
        padding: 15px;
        margin-top: 10px;
        border-radius: 4px;
        min-width: 200px;
        box-shadow: 0px 10px 20px -5px rgba(10, 42, 105, 0.06);
        .select2-search--dropdown{
            padding: 0;
            margin-bottom: 10px;
            @include radius4;
        }
    }
    
}

