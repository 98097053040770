// imp
@import "variable";
@import "mixins"; 

// Then import Bootstrap 
@import "../../../../node_modules/slick-carousel/slick/slick";
@import "../../../../node_modules/slick-carousel/slick/slick-theme"; 
@import "../../../../node_modules/select2/dist/css/select2.min.css";
@import "../../../../node_modules/nouislider/dist/nouislider.css";
@import "../../../../node_modules/fastbootstrap/dist/css/fastbootstrap.min.css";
@import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../../node_modules/cropperjs/dist/cropper.min.css";
@import "../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css"; 

// components
@import "banner"; 
@import "listing"; 
@import "footer"; 
@import "slider"; 
@import "filter"; 
@import "components";

 

// Animations 
// =====================================================
@keyframes fadeInAnimation {
    0%      {opacity: 0;}
    100%    {opacity: 1;}
}




// Reset
// =====================================================
html{
    overflow-x: hidden;
}
body{
    font-size: 15px;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    color: $dark2 !important; 

    animation: fadeInAnimation ease .3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
h1,h2,h3,h4,h5,h6{
    color: $dark;
    font-weight: 700 !important;
}
ul{
    margin: 0;
    padding: 0;
    li{
        list-style: none;
    } 
}
a{
    text-decoration: none !important;
    color: $primary;
} 
p{
    color: $dark2;    
}
.card{
    border: thin solid $grey2 !important;
} 
h1{
    font-size: 40px;
}
h2{
    font-size: 35px;
}
h3{
    font-size: 30px;
}
h4{
    font-size: 25px;
}
h5{
    font-size: 20px;
}
h6{
    font-size: 16px;
}
label{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
input{
    height: 50px;
    border: thin solid $grey2 !important;
    box-shadow: none !important;
    font-size: 15px !important;
    outline: none;
    padding: 0 1rem;
    @include radius4;
}
input[disabled]{
    background-color: #eee;
}
input[type="checkbox"]{
    height: initial;
}
::placeholder{
    color: $grey2 !important;
}
::-webkit-scrollbar {
    width: 13px;
}
::-webkit-scrollbar-track {
    background: $grey3;
}
::-webkit-scrollbar-thumb {
    background: $grey1;
}
::-webkit-scrollbar-thumb:hover {
    background: $primary;
}
.dropdown-divider{
    border-top: 1px solid $grey3 !important;
}


 


// Theme
// =====================================================
.form-control{
    font-size: 15px !important;
    box-shadow: none !important;
    outline: none !important; 
}
.bg-primary2{
    background-color: $primary !important;
}
.text-primary, .link-primary{
    color: $primary !important;
}
.text-secondary, .link-secondary{
    color: $secondary !important;
}
.btn{
    padding: 13px 30px !important;
    border: none !important; 
}
.btn.btn-primary{
    background: linear-gradient(90deg, $secondary 0%, $primary 100%) !important; 
    @include transition;
    &:hover{
        background: linear-gradient(90deg, $primary 0%, $primary 100%) !important; 
        @include transition;
    }
    &.btn-success{
        color: $white;
        background: #40C375 !important;
        @include transition;
        &:hover{
            color: $white;
        }
    }
} 
.btn.btn-primary2{
    background: $primary !important; 
    @include transition;
    &:hover{
        background: $secondary !important; 
        @include transition;
    } 
} 
.login-sign-up #map{
    pointer-events: none;
}
.btn.white-btn{
    background: $white !important;
    color: $dark;
    @include transition;
    &:hover{
        background: $primary !important; 
        color: $white;
        @include transition;
    }
} 
.btn.ghost-btn{
    color: $white;
    border: thin solid $white !important;
    @include transition;
    &:hover{
        background: $white !important; 
        color: $dark;
        @include transition;
    }
}
.btn.view-chat-btn{
    padding: 0 !important;
    background: $gradient;
    color: $white;
    width: fit-content;
    &.btn-dark{
        background: $dark;
    }
    & > div{
        gap: 5px;
        padding: 8px 20px;
        &:first-child{
            border-right: 1px solid rgba($color:$white, $alpha: 0.3);
        } 
    }
    &:hover{
        background-image: linear-gradient(225deg, #741F78 0%, #3A2A7E 100%);
        @include transition;
    }
    &:active{
        color: $white;
    }
    svg{
        height: 18px;
        width: 18px;
    }
}
.link-primary{
    &:hover{
        color: $secondary !important;
    }
}
.btn-success{
    color: $white;
    background: #40C375 !important;
    @include transition;
    &:hover{
        color: $white;
    }
}
#location-input{
    background-image: url(../img/icon-location-2.svg);
    background-repeat: no-repeat;
    background-position: 95%; 
    padding-right: 50px;
}
.banner form #location-input{
    background-position: 5% 55% !important;
    padding-right: 15px !important;
    padding-left: 40px;
}
.padding80{
    padding: 80px 0;
}

.bg-orange{
    background: $orange !important;
}
.text-orange{
    color: $orange !important;
}
.bg-green{
    background: $green !important;
}
.btn-secondary{
    background-color: $secondary;
    &:hover{
        background-color: $primary;
    }
}
.grey-bg{
    background-color: $grey3;
}

// select & inputs
.select2-container--default{
    .select2-search--inline{
        .select2-search__field{
            border: none !important;
        }
    }
}
.select2-container--default{
    .select2-results__option--highlighted.select2-results__option--selectable{
        background: $grey3;
        color: $dark2;
    }
    .select2-dropdown{
        border: 1px solid $grey3; 
        padding: 15px; 
        border-radius: 4px;
        min-width: 230px;
        box-shadow: 0px 10px 20px -5px rgba(10, 42, 105, 0.06);
        max-width: 100% !important;
        pointer-events: all !important;
        .select2-search--dropdown{
            padding: 0;
            margin-bottom: 10px;
            @include radius4;
            .select2-search__field{
                padding-left: 15px;
            }
        }
        .select2-results{
            .select2-results__option--selected{
                background-color: $grey3;
            }
        }
    }
} 


.select2-container{
    width: 100% !important;
    z-index: 999;  
    .select2-selection--single{
        border: 1px solid $grey2; 
        height: 50px;
        .select2-selection__rendered{
            color: $dark2;
            font-size: 14px;
            font-weight: 500;
            line-height: 50px;
            padding-left: 15px;
        }
        .select2-selection__arrow{
            background-image: url(../img/arrow-down.svg);
            background-position: center;
            background-repeat: no-repeat;
            top: 12px;
            right: 10px;
            b{
                display: none;
            }
        }
    }
}

// multi select
.select2-container--default{
    .select2-selection--multiple{
        // padding: 4px;
        min-height: 50px;
        padding-bottom: 0;
        // line-height: 40px !important;
        @include border-radius;
        .select2-selection__choice{
            margin: 0;
            margin-right: 5px;
            // margin-bottom: 5px;
            background: $grey2 !important;
            padding: 7px 5px;
            margin-top: 5px;
            border: none;
            .select2-selection__choice__remove{
                border: none;
                margin-right: 5px;
                span{
                    font-size: 20px;
                    font-weight: 200;
                    line-height: 0px;
                }
            }
        }
    }
    .select2-search__field{
        margin: 0 !important;
        height: 40px !important;
        border: thin solid $grey3;
        // border: none !important; 
    }
}
.select2-container--default .select2-results>.select2-results__options{
    text-align: left;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: $grey3;
    color: $dark2;
}
.select2-container--default.select2-container--focus{
    .select2-selection--multiple{
        @include border-radius;
        height: auto;
        min-height: 50px;
    }
}
// range
.noUi-target{
    &.noUi-horizontal {
        height: 8px;
    }
    .noUi-handle{
        width: 24px;
        height: 24px;
        background: $white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: none;
        top: -10px !important;
        &::before{
            display: none;
        }
        &::after{
            display: none;
        }
        .noUi-touch-area{
            width: 12px;
            height: 12px;
            background: $secondary;
            border-radius: 20px;
        }
        .noUi-tooltip{
            background-color: $grey2;
            padding: 4px 8px;
            border-radius: 50px;
            font-weight: 600;
            bottom: -40px;
            &::before{
                content: "";
                width: 10px;
                height: 10px;
                display: block;
                background-color: $grey2;
                position: absolute;
                top: -5px;
                left: calc(50% - 5px);
                transform: rotate(45deg);
            }
        }
    }
    .noUi-connects{
        .noUi-connect{
            background: $primary;
        }
    }
}


 

//  main css

.loader{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(225deg, $secondary 0%, $primary 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.splash-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; 
    .slick-slide{
        height: 100vh;
        background-size: cover;
        background-position: center;
    }
}
.splash-text{
    width: 100%;
    height: 100vh; 
    .content-slider{
        text-align: center;
        color: #fff;
        max-width: fit-content;
        height: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        h1{
            color: $white;
        }
        a{
            line-height: 24px;
        }
    }
}

body.home{
    &::before{
        content: '';
        width: 100%;
        height: 300px;
        display: block;
        background-image: url(../img/head-bg.png);
        background-size: cover;
        position: absolute;
        z-index: -1;
    }
}

header.logout{
    padding: 15px 0;
    .logo{
        img{
            height: 80px;
        }
    }
    nav{
        ul{
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            a{
                &:hover{
                    opacity: .8;
                    @include transition;
                }
            }
            .light-bg{ 
                background-color: rgba($color: #fff, $alpha: 0.1);
            }
        }
    }
} 

header.loggedin{
    padding: 15px 0;
    .logo{
        img{
            height: 70px;
        }
    }
    nav{
        ul{
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end; 
            .avtar{
                padding: 0 !important;
                img{
                    height: 50px;
                    width: 50px;
                    object-fit: cover;
                }
                &::after{
                    display: none;
                }
            }
            .notification,.help{
                background: rgba($color: #fff, $alpha: 0.1);
                width: 50px;
                height: 50px;
                padding: 0 !important;
                border-radius: 50px;
                position: relative;
                display: grid;
                justify-content: center;
                align-items: center;
                @include transition;
                svg{
                    height: 30px;
                    path{
                        @include transition;
                    }
                }
                &:hover{
                    background: rgba($color: #fff, $alpha: 1);
                    @include transition;
                    path{
                        fill: $primary;
                        @include transition;
                    }
                }
                &::after{
                    display: none !important;
                }
            }
            .notification{
                .active{
                    background-color: #40C375;
                    height: 12px;
                    width: 12px;
                    border-radius: 10px;
                    position: absolute;
                    top: 2px;
                    right: 2px;
                }
            } 
        }
    }
    .menu{
        ul{
            gap: 30px; 
            li{
                padding: 5px 0;
            }
            a{
                color: $white;
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 17px;
                @include transition;
                svg{
                    height: 18px;
                    width: 18px;
                    path{
                        @include transition;
                    }
                }
                &:hover{
                    color: #E98FB6;
                    @include transition;
                    path{
                        fill: #E98FB6;
                        @include transition;
                    } 
                }
                &.active{
                    color: #E98FB6 !important;
                    @include transition;
                    path{
                        fill: #E98FB6;
                        @include transition;
                    }
                }
            }
        }
    }
    &.inner{
        // background: $gradient;
        background-image: url(../img/head-bg.png);
        position: relative;
        z-index: 99999999;
    }
    .dropdown-menu{
        display: none;
        &.show {
            display: block;
        }
    }
} 

.login-sign-up{
    width: 100%;
    height: 100vh;
    background-image: url(../img/login-bg.jpg);
    background-size: cover;
    display: flex;
    .content-section{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
        img{
            height: 190px;
        }
        h3{
            color: $white;
            font-size: 30px;
            margin-top: 50px;
        }
    }
    .form-container{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        .form{
            width: 100%;
            max-width: 500px; 
            padding: 40px 50px;
            background: $white;
            @include radius8;
            p{
                text-align: center;
            }
            form{ 
                input[type=password]{
                    background-position: 88%;
                }
                input{
                    width: 100%;  
                    background-position: 89%;
                    &.select2-search__field{
                        // border: none !important;
                        &::placeholder {
                            padding-left: 10px;
                            color: #999 !important;
                        }
                    }
                }
                input[type=checkbox]{
                    width: fit-content;
                }
                .otp{
                    input{
                        text-align: center;
                        font-size: 20px;
                        line-height: 50px;
                        font-weight: 600;
                    }
                }
                
                
            }
        }
        .signup-with{
            text-align: center;
            margin-top: 30px;
            ul{
                display: flex;
                gap: 10px;
                width: fit-content;
                margin:auto;
                padding: 20px 80px;
                justify-content: center;
                border-bottom: 1px solid $grey2;
                margin-bottom: 20px;
            }
        }
    }
    .account-type{
        label{
            @include border-radius;
            @include radius8;
            padding: 20px;
            text-align: center;
            width: 100%;
            background-color: $grey3;
            cursor: pointer;
        }
        input{
            &[type="radio"]{
                display: none;
            }
            &:checked{
                & + label{
                    background: $gradient;
                    p{
                        color: $white;
                    }
                    path{
                        fill: $white;
                    }
                }
            }
        }
        p{
            margin-top: 10px;
            margin-bottom: 0;
            display: block;
            font-size: 16px;
            font-weight: 600;
        }
    }
}
.show-pass{
    position: relative;
    .is-invalid{
        + .toggle-password{
            bottom: 37px; 
        }
    }
    .toggle-password{
        position: absolute;
        bottom: 15px; 
        right: 15px;
        margin: auto; 
        height: fit-content; 
        &.active{
            svg{
                path, line, circle{
                    stroke: $primary;
                }
            } 
            .feather-eye-off{
                display: none;
            }
            .feather-eye{
                display: block;
                margin-top: 3px;
            }
        }
        svg{
            height: 20px;
            path, line, circle{
                stroke: $dark2;
            }
        }
        .feather-eye{
            display: none;
        }
    }
} 

.job-post-head{
    padding: 40px;
    position: relative;
    overflow: hidden;
    .ad-agency{
        position: absolute;
        top: 0;
        background: #ccc;
        color: #333;
        left: 0;
        right: 0;
        width: fit-content;
        margin: auto;
        z-index: 999;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        padding: 2px 8px;
        border-radius: 0 0 8px 8px;
    }
    .featured-label{
        color: #fff;
        position: absolute;
        z-index: 999;
        top: 0px;
        background: linear-gradient(90deg, #EA4931 0%, #F9AE08 100%);
        padding: 2px 15px;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;    
        border-radius: 0 0 10px 10px;
        display: none;
    }
    .row{
        position: relative;
        z-index: 9;
    }
    h4{
        margin-bottom: 10px;
        img{
            height: 25px;
        }
    }
    &.featured{
        .featured-label{
            display: block;
        } 
        &::before{
            content: '';
            width: 1500px;
            height: 1500px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: conic-gradient(#32A753, #4185F4, #FBBA03, #EA4732);
            z-index: 0;
            filter: blur(10px);
            -webkit-filter: blur(10px);
            -moz-filter: blur(10px);
            -ms-filter: blur(10px);
            -o-filter: blur(10px);
            @include radius8;
            -webkit-animation: spin 5s linear infinite;
            animation: spin 5s linear infinite;
        }
        &::after{
            content: '';
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            position: absolute;
            top: 3px;
            left: 3px;
            background: #F8FAFF;
            z-index: 1;
            @include radius4;
        }
    }
}

.job-post-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    border-top: thin solid rgba($color: $grey2, $alpha: 0.8);
}

.details-listing{
    column-count: 2;
    column-gap: 30px;
    li{
        display: flex;
        padding: 10px 0;
        div{
            &:first-child{
                width: 40%;
                color: #66789C;
                svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                }
            }
            &:last-child{
                width: 60%;
                position: relative;
                padding-left: 15px;
                font-weight: 600;
                &::before{
                    content: ':';
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

.sidebar-profile{
    .avtar{
        margin-right: 10px;
        img{
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }
    .details{
        h5{
            font-size: 18px;
        }
        svg{
            height: 20px;
            stroke: #E74C3C;
        }
        span{
            color: $grey1;
        }
        a{
            color: $dark;
            text-decoration: underline !important;
            margin-top: 10px;
            display: inline-block;
        }
    }
}



.create-profile{
    .form-container{
        background-color: $white;
        .form{
            width: 80%;
            min-width: 350px;
            padding: 0;
            form{
                max-width: 100%;
                margin: auto;
            }
        } 
    }
    .content-section{
        position: fixed;
        left: 0;
        top: 0;
        width: 50%;
        height: 100vh;
    }
    .form-container{
        position: fixed; 
        right: 0;
        top: 0;
        width: 50%;
        height: 100vh; 
    }
}

.form-container{
    .scroll-div{
        height: 65vh;
        overflow-x: hidden;
        padding: 0 30px;
    }
}

.main-filter{
    margin-top: 24px;
    .filter-each{
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        background: #eee;
        padding: 15px;
        position: relative;
        @include transition;
        @include radius8;
        &.filter-on{
            background: $gradient !important;
            &::before{
                content: "Filter On";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -13px;
                width: -moz-fit-content;
                width: fit-content;
                background-color: #40C375;
                margin: auto;
                color: #fff;
                font-weight: 700;
                padding: 3px 10px;
                font-size: 12px;
                border-radius: 15px;
                border: 2px solid #fff;
                text-transform: uppercase;
            }
        }
        .icon{
            min-width: 60px;
            height: 60px;
            background-color: rgba($color: $white, $alpha: 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 70px;
            svg{
                height: 40px;
            }
        }
        span{
            width: 100%;
            text-align: center;
            font-weight: 600;
            color: $white;
        }
        &:hover{
            background: $secondary !important;
            @include transition;
        }
    }
}

.slideIn-modal{
    position: fixed;
    top: 0;
    left: -450px;
    background-color: $white;
    width: 100%; 
    max-width: 400px; 
    height: 100vh; 
    // padding: 24px;  
    z-index: 999999;
    @include shadow;
    @include transition;
    &.open{
        left: 0;
        @include transition;
    }
    ul{
        height: calc(100vh - 200px);
        overflow-y: scroll;
        width: 99%;
        li{
            display: flex;
            // border-bottom: thin solid $grey3; 
            input{
                display: none;
                &:checked{
                    &+ label{
                        color: $primary;
                        &::after{
                            background-color: $primary;
                        }
                    }
                }
            }
            label{
                padding: 7px 5px 7px 50px;
                position: relative;
                width: 100%;
                &::before{
                    content: '';
                    min-width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    display: inline-block;
                    border-radius: 20px; 
                    @include border;
                    position: absolute;
                    left: 24px;
                    top: 8px;
                }
                &::after{
                    content: "";
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    top: 12px;
                    left: 28px;
                    background-color: $white;
                    border-radius: 20px;
                }
            }
        }
    } 
} 

.help-links{
    li{
        display: flex;
        border-bottom: thin solid $grey3 !important;
        @include transition;
        &:hover{
            background-color: $grey3;
            padding-left: 24px;
            @include transition;
        }
        span{
            display: inline-block;
        }
    }
}
.location.address svg path{
    fill: #741F78;
}
.feature-ad{
    @include radius8;
    // background: radial-gradient(68.57% 50% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, #E0E6F7 100%);
    background: linear-gradient(45deg, #f67e7d, #ffb997);
    padding: 60px 40px;
    margin-top: 60px;
    h2{
        color: #fff;
        text-transform: uppercase;
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-weight: 900 !important;
        font-size: 35px;
    }
    h6{
        color: #fff;   
        font-size: 20px;
    }
    a{
        width: 100%;
        position: relative;
        overflow: hidden;
        span{
            position: relative;
            z-index: 9;
        }
        &::before{
            content: '';
            width: 400px;
            height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: conic-gradient(#32A753, #4185F4, #FBBA03, #EA4732);
            z-index: 0;
            filter: blur(10px);
            -webkit-filter: blur(10px);
            -moz-filter: blur(10px);
            -ms-filter: blur(10px);
            -o-filter: blur(10px);
            @include radius8;
            -webkit-animation: spin 5s linear infinite;
            animation: spin 5s linear infinite;
        }
        &::after{
            content: '';
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            position: absolute;
            top: 3px;
            left: 3px;
            background: #F8FAFF;
            z-index: 1;
            @include radius4;
        }
        &:hover{
            &::after{
                background-color: $primary;
                @include transition;
            }
            span{
                color: $white;
                @include transition;
            }
        }
    }
}

.feature-btn{
    position: relative;
    overflow: hidden;
    span{
        position: relative;
        z-index: 9;
    }
    &::before{
        content: '';
        width: 400px;
        height: 400px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: conic-gradient(#32A753, #4185F4, #FBBA03, #EA4732);
        z-index: 0;
        filter: blur(10px);
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -ms-filter: blur(10px);
        -o-filter: blur(10px);
        @include radius8;
        -webkit-animation: spin 5s linear infinite;
        animation: spin 5s linear infinite;
    }
    &::after{
        content: '';
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        position: absolute;
        top: 3px;
        left: 3px;
        background: $gradient;
        z-index: 1;
        @include radius4;
    }
} 

.feature{
    label{
        height: 100%;
    }
    .plan{
        background: #ccc;
        padding: 20px;
        border-radius: 12px;
        height: 100%;
        h5{
            font-weight: 700;
            color: $white;
            font-size: 22px;
        }
        h4{
            font-weight: 700;
            color: $white;
            font-size: 16px;
            margin-top: 80px;
        }
        h3{
            font-weight: 900;
            color: $white;
            font-size: 22px;
        }
        small{
            font-size: 14px;
            color: $white;
        }
    }
    .bronze{
        background-image: url(../img/bronze.png);
        background-size: cover;
        @include transition;
    }
    .silver{
        background-image: url(../img/silver.png);
        background-size: cover;
        @include transition;
        h3,h4,h5,small{
            color: $dark;
        }
    }
    .gold{
        background-image: url(../img/gold.png);
        background-size: cover;
        @include transition;
    }
    .diamond{
        background-image: url(../img/diamond-bg.png);
        background-size: cover;
        @include transition;
    }
    input[type="radio"]{
        display: none; 
        &:checked{
            &+ label{
                border: 5px solid rgba($color: $secondary, $alpha: 0.8);
                @include transition; 
                &::after{
                    opacity: 1;
                }
            }
        }
    }
    label{
        position: relative;
        border: 5px solid $white;
        width: 100%;
        height: 100%;
        &::before{
            content: '';
            width: 25px;
            height: 25px; 
            background: $white;
            position: absolute;
            top: 10px;
            right: 10px;
            @include radius4;
        }
        &::after{
            content: '';
            width: 15px;
            height: 8px;
            transform: rotate(-45deg); 
            border-bottom: 3px solid $primary;
            border-left: 3px solid $primary;
            position: absolute;
            top: 17px;
            right: 15px;
            border-bottom-left-radius: 3px; 
            opacity: 0;
        }
    }
}

.profile{
    .nav-tabs{
        border: none;
        .nav-item{
            .nav-link{
                padding: 15px 25px;
                text-align: left;
                margin-bottom: 2px;
                @include radius4;
                color: $dark;
                display: flex;
                align-items: center;
                svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                &.active{
                    color: $white !important;
                    &::before{
                        content: '';
                        display: block;
                        position: absolute;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-left: 10px solid #3f297d;
                        right: -10px;
                    }
                }
            }
        }
    }
    .card{
        border: none !important;
        @include shadow;
        .edit-btn{
            font-weight: 600;
            svg{
                height: 15px;
                stroke-width: 3px;
            }
        }
        .details{
            label{
                font-weight: 300;
                & + p{
                    font-weight: 600;
                    color: $dark;
                }
            }
        }
    }
}

.candidate-profile-head{
    background-color: $grey3;
    padding: 60px 0;
    .user-img{
        position: relative;
        height: fit-content; 
        img{
            border: 5px solid $white;
        }
        label{
            background-color: $white;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -10px;
            right: 0px;
            left: 0;
            margin: auto;
            cursor: pointer;
            border: thin solid rgba($color: $grey2, $alpha: 0.7);
            svg{
                width: 15px;
                height: 15px;
                stroke-width: 3px;
            }
        }
    }
    .info{
        width: 100%;
        h4{
            font-size: 24px;
            svg{
                height: 20px;
                stroke: $primary;
                margin-left: 5px;
            }
        }
        ul{
            column-count: 2;
            li{
                padding: 5px 0;
                font-weight: 500;
                color: $dark;
                display: flex;
                align-items: center;
                svg{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    stroke: $primary;
                }
            }
        }
        .progress{
            height: 10px;
            background-color: $white;
        }
    }
    .pending-info{
        background: $white;
        padding: 30px;
        @include radius8;
        margin-left: 50px;
    }
    .candidate-info{
        .profile-pic{
            .dp-update{
                padding: 5px 15px !important;
                font-size: 14px !important;
                height: auto;
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                width: fit-content;
                margin: auto;
                border-radius: 30px;
                line-height: 20px;
            }
        }
    }
}

.app-dwnld{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
    .card{ 
        width: 100%;
        max-width: 500px; 
        z-index: 999;
        padding: 30px;
        @include radius8;
        background: $white;
        .form-control{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .btn{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    .btn-close{
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 999;
    }
}

// filter mobile menu
.mobile-filterBtn{
    background: $primary; 
    border-radius: 4px;
    display: none;
    align-items: center;
    justify-content: center; 
    width: 35px !important;
    height: 35px !important;
    svg{
        height: 20px;
        stroke: $white;
        stroke-width: 1px;
    }
    .close{
        display: none
    }
    &:active{
        background: $grey3 !important;
    }
    &:hover{
        background: $secondary !important;
    }
    &.btn{
        padding: 0 5px !important;
    }
}  
.mobile-filter{
    position: initial; 
    width: 25%;
    height: 100%;
}

.mobile-bottomNav{
    display: none;
    ul{
        li{
            span{
                color: $white;
                font-weight: 500;
            }
            ul{ 
                li{
                    a{
                        color: $dark;
                        padding: 10px 20px !important; 
                        @include transition; 
                        &:hover{
                            background: $gradient;
                            color: $white;
                            @include transition;
                        }
                        &.active{
                            color: #E98FB6;
                        }
                    }
                }
            }
        }
    }
}

.post-listing-home{
    .post-listing-tabs{
        background-color: rgba($color: $grey3, $alpha: 0.8);
        position: sticky;
        top: 0;
        z-index: 999;
        backdrop-filter: blur(10px);
        // box-shadow: 0 2px 5px rgba($color: $dark, $alpha: 0.1); 
        button{
            padding: 8px 30px !important;
            background-color: $white;
            &.active{
                background: $gradient;
                color: $white;
            }
        }
    }
} 

.tab-content{
    .feature{
        .plan{
            h4{
                font-weight: 500 !important; 
                font-size: 12px;
                margin-top: 30px;
            }
            h3{
                font-size: 20px;
            }
        }
    }
}
.suggessions .scroll ul:after {
    content: '';
    min-width: 5px;
    height: 30px;
    display: block;
}
.payment-table{
    .left{
        width: 40%;
    }
    .item{
        display: flex;
        padding: 10px;
        background-color: #f9f9fb;
        .date{
            max-width: fit-content;
            background: $white;
            min-width: 70px;
            height: 50px;
            border-radius: 8px;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            & > div{
                height: fit-content;
            }
            h6{
                width: 100%;
                margin: 0;
                font-weight: 800 !important;
                font-size: 20px;
                line-height: 20px;
            }
            small{
                color: $grey1;
                font-size: 11px;
            }
        }
        .details{
            small{
                color: $grey1 !important; 
            }
        }
        .amount{
            width: 20%;
            display: flex;
            align-items: center;
            h2{
                font-size: 20px;
                margin: 0;
                font-weight: 800 !important;
            }
        }
        .invoice{
            width: 20%;
        }
        .status{
            width: 20%;
            small{
                font-weight: 700;
            }
        }
        &.success{
            .amount{
                h2{
                    color: $green;
                }
            }
            .status{
                small{
                    font-weight: 700;
                    color: $green;
                }
            }
        }
        &.failed{
            .amount{
                h2{
                    color: $red;
                }
            }
            .status{
                small{
                    font-weight: 700;
                    color: $red;
                }
            }
        }
        &:nth-child(2n+1){
            background-color: $grey3;
        }
    } 
}

.fav.added{
    path{
        fill: $primary;
    }
}

.testimonial{
    background-color: rgba($color: $grey3, $alpha: 0.3);
}

.emp-profile{
    .details{
        .btn{
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}

.posted-jobs-nav{
    gap: 5px;
    li{
        a{
            padding:13.5px 30px; 
            border-color: $grey3 !important;
            &:hover{
                background-color: $grey3;
            }
        }
    }
}

.select-candidate{
    .select2-container{
        width: 100% !important;
        // z-index: 99999999 !important;
        position: relative;
    }
}
.modal-backdrop + .select2-container{
    z-index: 999999999;
} 

.more-detailsBtn{
    padding: 15px 20px;
    background-color: $grey3;
    color: $dark;
    border: none;
    width: 100%;
    text-align: left;
    @include radius8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.more-detailsBtn.open{
    svg{
        line{
            &:first-child{
                display: none;
            }
        }
    }
}

.postJob-moreDetails{
    opacity: 0.4;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    @include transition;
    &.show{
        opacity: 1;
        overflow: visible;
        height: fit-content;
        pointer-events: all;
        @include transition;
    }
}


.congrats{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    .form{
        background: transparent !important;
        padding-bottom: 150px;
    }
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-image: url(../img/doodle1.png);
        background-repeat: repeat-x;
        background-position:left bottom;
        background-size: 30%;
        opacity: .7;
    }
    img{
        max-width: 300px;
        width: 100%;
    }
}

#companydetails .select2{
    width: 100% !important;
}

.by-agency{
    position: absolute;
    background: #eee;
    padding: 3px 8px;
    border-radius: 20px;
    color: #333;
    font-weight: 500;
    right: 0;
    top: 75px;
    font-size: 13px;
}

.link-primary:focus, .link-primary:hover{
    color: $primary !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
    background-position: 88% !important;
} 

#share-btn .close{
    display: none;
}
#share-btn{
    padding: 5px;
    border-radius: 60px;
}
#share-btn.show{
    background-color: $grey3;
}
#share-btn .share-links{
    opacity: 0;
    translate: -50px 0;
    transition:all .3s ease;
    pointer-events: none;
    width: 0;
}
#share-btn.show .share-links{
    opacity: 1; 
    translate: 0px 0;
    pointer-events: all;
    width: fit-content;
    transition:all .3s ease;
}
#share-btn.show .share{
    display: none;
}
#share-btn.show .close{
    display: block;
}
#share-btn.show > .grey-btn-rounded-icon{
    background-color: #741F78;
}
#share-btn.show > .grey-btn-rounded-icon svg{
    stroke: white;
} 
#share-btn.show .share-links a{
    background-color: $white;
}
#share-btn.show .share-links a:hover{
    background-color: $primary;
}
#share-btn.show .share-links a:hover svg{
    stroke: $white;
}

.page_loader{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999;
    backdrop-filter: blur(1px);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    &.show{
        opacity: 1;
        pointer-events: all;
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
    }
} 
.block-section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.placeholder{
    background-color: $grey2 !important;
}
 
// .main-listing{}
.loading.main-listing .card-grid:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%; 
    opacity: 0; 
    pointer-events: none;
    background-image: url(../img/shimmer-post.png);
    background-size: 99%;
    background-position: center;
    background-color: #fff;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
.loading.main-listing .card-grid{
    border: 1px solid $grey3 !important;
}
.loading.main-listing .card-grid:after{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%; 
    opacity: 1; 
    z-index: 999;
}
.loading.main-listing .card-grid:before{
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: shimmer 3s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 40%, $grey3 50%, rgba(255, 255, 255, 0) 60%);
    background-size: 1000px 100%;
    z-index: 99;
}
@keyframes shimmer {
    from {
      background-position: -1000px 0;
    }
    to {
      background-position: 1000px 0;
    }
}
.feature #map{
    height: 0 !important;
    overflow: hidden;
}
.notifications{
    a{
        cursor: pointer !important;
    }
} 
.modal{
    z-index: 999999999;
}
.modal-backdrop.show{
    z-index: 99999999;
}
.pac-container{
    z-index: 999999999999; 
    // box-shadow: none !important;
    // border: none !important;
}
form.processing{
    button{
        .spinner-border{
            display: inline-block !important;
        }
    }
}

.chat-tab{
    .nav-tabs{
        .nav-item{
            border: none;
        }
    }
    .head-dlt{
        button{
            padding: 10px !important;
            display: inline-flex;
            svg{
                height: 20px;
            }
        }
    }
    ul{
        li{
            a{
                padding: 15px;
                @include transition;
                input{
                    height: 20px;
                    width: 20px;
                    padding: 0;
                    margin-right: 15px;
                }
                .user{
                    width: 30%;
                    small{
                        color: $grey1;
                    }
                }
                .msg{
                    width: 40%;
                    color: $grey1;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box !important;
                    overflow: hidden;
                }
                .time{
                    width: 15%;
                    color: $grey1;
                }
                .actions{
                    width: 15%;
                }
                &:hover{
                    background-color: rgba($color: $grey3, $alpha: 0.8);
                    @include transition;
                }
            }
        }
    }
}
.chat-single{
    .job-details{
        img{
            width: 60px;
            height: 60px;
            object-fit: cover;
        }
        p{
            font-size: 14px;
            svg{
                height: 18px;
                width: 18px;
            }
        }
    }
    .message{ 
        background-color: $grey3;
        position: relative;
        .head{
            // position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $grey3;
            button{
                background-color: #e6e6eb !important;
                padding: 0;
                width: 40px;
                height: 40px;
                svg{
                    height: 20px;
                    stroke: #333;
                } 
            }
            .dropdown-item{
                font-size: 14px;
            }
            img{
                box-shadow: 0 0 0 3px $primary;
            }
        }
        .suggessions{
            // position: absolute;
            left: 0;
            bottom: 59px;
            background: $grey3;
            width: 100%; 
            padding: 5px 30px;
            position: absolute;
            .scroll{
                background: $grey2;  
                height: 100%;
                padding: 15px;
                overflow: auto;
                border-radius: 8px;
                &::-webkit-scrollbar{
                    height: 5px;
                    background: $secondary;
                    @include transition;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba($color: $secondary, $alpha: 0.3);
                }
                &:hover{
                    &::-webkit-scrollbar{
                        height: 5px;
                        @include transition;
                    }
                }
                ul{
                    align-items: center; 
                    display: flex;
                    gap: 10px;
                    li{
                        background-color: $white;
                        padding: 5px 10px;
                        color: $dark2;
                        white-space: nowrap;
                        border-radius: 4px;
                        font-size: 14px;
                        cursor: pointer; 
                    }
                }
            }
        }
        .chatbox{
            max-height: 50vh;
            height: 100%;
            overflow: auto;
            margin: 10px 0;
            min-height: 400px;
            &::-webkit-scrollbar{
                width: 8px;
                background: $secondary;
            }
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color: $secondary, $alpha: 0.3);
            }
            ul.chat{
                float: left;
                width: 100%;
                margin-bottom: 70px;
                li{
                    display: flex;
                    clear: both;
                    float: left;
                    padding: 7px 20px 7px 30px;
                    width: 100%;
                    font-size: 0.9em;
                    .img{
                        img{
                            width: 180px !important;
                            height: 130px !important;
                            object-fit: cover;
                            border: 5px solid #fff;
                            border-radius: 8px !important;
                            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
                            cursor: pointer;
                        }
                    }
                    &.label{
                        small{
                            background: #fff;
                            padding: 3px 8px;
                            line-height: 20px;
                            border-radius: 20px;
                            font-weight: 700;
                            font-size: 10px;
                        }
                        hr{
                            border: none;
                            height: 1px;
                            background: #fff;
                            opacity: 1;
                            width: 50%;
                            margin: auto;
                            margin-top: -10px;
                            margin-bottom: 0;
                        }
                    }
                    &.sent {
                        flex-direction: row-reverse;
                        justify-content: end;
                        img {
                            float: right;
                            height: fit-content;
                            margin: 0px 0 0 8px;
                        } 
                        p {
                            background: $gradient;
                            color: $white;
                            float: right;
                            margin: 0;
                        }
                    }
                    &.replies { 
                        img {
                            margin: 0px 8px 0 0;
                            height: fit-content;
                        } 
                        p{
                            background: $white;
                            color: $dark2;
                            margin: 0;
                        }
                    } 
                    img {
                        width: 30px;
                        border-radius: 50%;
                        float: left;
                    } 
                    p{
                        display: inline-block;
                        padding: 7px 10px;
                        border-radius: 15px;
                        max-width: 350px;
                        line-height: 130%;
                    }
                }
            }
        }
        .footer-tab{
            // position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 9999;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: $grey3;
            .attachments{
                justify-content: flex-end;
                position: absolute;
                height: 30px;
                small{ 
                    align-items: center;
                    display: flex;
                    gap: 5px; 
                    background: #fff;
                    button.attachment{
                        height: 20px;
                        min-width: 20px;
                        padding: 0 !important;
                        margin-left: 5px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }
            }
            .field{
                background: $grey3;
                border: none !important;
            }
            button{
                min-width: 50px;
                height: 50px;
                padding: 0 !important;
                svg{
                    height: 20px;
                    width: 20px;
                }
            }
            .file-upload{
                cursor: pointer;
            } 
        }
    }
} 




@import "responsive"; 
 