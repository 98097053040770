// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 1400px){}


// X-Large devices (large desktops, 1200px and up)
@media (max-width: 1200px){
    .congrats{ 
        .form{ 
            padding-bottom: 100px;
        }
        &::before{  
            background-size: 45%;
            opacity: .7;
        } 
    }
}


// Large devices (desktops, 992px and up)
@media (max-width: 992px) { 
    .container{
        max-width: 95%;
    }
    .login-sign-up{
        .content-section{
            width: 40% !important;
            img{
                height: 130px;
            }
        }
        .form-container{
            width: 60% !important;
        }
    }
    
    // header loggedin
    header.loggedin{
        .logo{
            img{
                height: 80px;
            }
        }
        .menu{
            ul{
                gap: 20px;
                a{
                    font-size: 15px;
                    gap: 5px;
                }
            }
        }
    }
    .banner{
        .banner-box{
            form{
                text-align: left;
                flex-wrap: wrap;
                gap: 5px;
                .select2{
                    max-width: 100% !important;
                    border: none;
                }
                .industry{
                    & + span{
                        &::after{
                            display: none;
                        }
                    }
                }
                .location{
                    & + span{
                        &::after{
                            display: none;
                        }
                    }
                }
            }
        }
    } 
    .mobile-filter-open{
        overflow: hidden; 
        .mobile-filter{ 
            opacity: 1;
            pointer-events: all;
        }
        .mobile-filterBtn{ 
            z-index: 999999999;
            background: $primary !important;
            position: fixed;
            top: 0px;
            right: 0px;
            border-radius: 0;
            padding: 0 !important;
            width: 50px !important;
            height: 50px !important;
            .open{
                display: none;
            }
            .close{
                display: block; 
            }
        }
        .select2-container{
            width: 100% !important;
            z-index: 999999;
        }
    } 
    .mobile-filterBtn{
        display: flex;
    }
    .mobile-filter{ 
        position: fixed;
        display: block !important;
        width: 100% !important;
        height: 100%;
        background-color: $white;
        padding: 30px;
        left: 0;
        top: 0;
        z-index: 99999999;
        overflow: auto;  
        opacity: 0;
        pointer-events: none;
    }
    .job-post-head{
        .justify-content-end{
            justify-content: start !important;
        }
    }
    .feature-ad{
        margin-top: 60px;
        margin-bottom: 30px;
        h2{
            font-size: 35px;
        }
    }
    .candidate-profile-head{
        .pending-info{
            margin: 0;
            padding: 20px;
        }
    }
    .list-exclamation{
        li{
            padding: 5px 5px 5px 25px;
            position: relative;
            font-size: 14px;
            &::before{
                width: 15px;
                height: 15px;
                top: 8px;
            }
        }
    }
    .feature-info{
        img{
            max-height: 400px;
            width: auto !important;
        }
    }
    .congrats{ 
        .form{ 
            padding-bottom: 50px;
        }
        &::before{  
            background-size: 40%;
            opacity: .7;
        } 
    }
}



// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {  
    h2{
        font-size: 30px;
    }
    .loader{
        img{
            max-width: 130px; 
        }
    }
    .splash-text{
        .content-slider{
            padding: 0 20px;
            h1{
                font-size: 35px;
            }
        } 
    }
    .login-sign-up{
        .content-section{
            display: none !important; 
        }
        .form-container{
            width: 100% !important;
        }
    } 
    // header loggedin
    header.loggedin{ 
        .menu{
            display: none;
        }
    }

    .mobile-bottomNav{
        width: 100%;
        height: 55px;
        z-index: 9999999;
        display: block;
        position: fixed;
        bottom: -1px;
        left: 0;
        background: $gradient;
        & > ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 0 50px;
            button{
                &::after{
                    display: none;
                }
            }
            a, button{
                display: flex;
                align-items: center;
                gap: 10px;
                color: $white; 
            }
        }
    }
    header.logout{ 
        nav{
            a{
                width: 100%;
                display: block;
                padding: 13px 15px !important;  
            }
        }
    }
    footer{
        .copy{
            text-align: center;
            p{
                text-align: center;
            }
        }
    } 
    .btn {
        padding: 10px 15px !important; 
        font-size: 14px !important;
        height: initial;
    }  
    .job-post-head{
        .whatsapp{
            img{
                height: 41px;
            }
        }
        h4{
            font-size: 22px;
        }
        .post-info{
            div{
                font-size: 14px;
            }
        }
    } 
    .details-listing{
        column-count: 1;
    }
    .listing-title{
        .sort{
            // justify-content: space-between !important;
        }
    }
    .whatsapp{
        img{
            height: 41px;
        } 
    }
    .feature-ad{
        margin-top: 60px;
        padding: 30px;
        margin-bottom: 30px;
        h2{
            font-size: 30px;
        }
    }
    .inner-title{
        h3{
            font-size: 26px;
        }
    }
    .candidate-profile-head{
        .user-img{
            min-width: 80px;
            margin-right: 15px !important;
            width: fit-content;
            margin-bottom: 20px;
        }
        .info{
            h4 {
                font-size: 20px;
            }
        }
    }
    footer{
        .copy{
            padding-bottom: 80px !important;
        }
    }
    .inner-title{
        h4{
            font-size: 22px;
        }
    }
    .congrats{ 
        .form{ 
            padding-bottom: 70px;
        }
        &::before{  
            background-size: 60%;
            opacity: .7;
        } 
    }
}



// Small devices (landscape phones, 576px and up)
@media (max-width: 575px) {
    .congrats{  
        &::before{  
            background-size: 75%; 
            opacity: .8; 
        } 
    }
    .inner-title{
        h4{
            font-size: 20px;
        }
    }
    .container{
        max-width: 100%;
    }
    header.loggedin{
        .logo{
            img {
                height: 60px;
            }
        }
    }
    .loader{
        img{
            max-width: 130px;
            margin-top: -200px;
        }
    }
    .splash-text{
        .content-slider{
            padding: 0 20px 180px 20px;
            h1{
                font-size: 30px;
            }
        } 
    } 
    .login-sign-up{
        min-height: 100vh;
        height: 100%;
        align-items: center;
        .form-container{
            padding: 20px;
            .form{
                padding: 20px;
                min-width: 100%;
                .d-flex{
                    font-size: 14px;
                }
                &.profile{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            .signup-with{
                ul{
                    width: 100%;
                }
            }
        }
    }
    .gender-input-group{
        label{
            padding-left: 10px;
            padding-right: 10px;
        }
    }  
    .banner{
        .banner-box{
            padding: 40px 24px;
            h1{
                font-size: 26px;
            }
            form{
                margin-bottom: 0;
            }
            &::before{
                width: 100%;
            }
        }
    }
    .main-filter{
        .filter-each{
            padding: 10px;
            gap: 5px;
            .icon{
                height: 50px;
                min-width: 50px;
                width: 50px;
                svg{
                    height: 30px;
                    width: 30px;
                }
            }
            span{
                font-size: 14px;
            }
        }
    }
    .slideIn-modal{
        width: 85%;
        li{
            label{
                padding: 7px 10px 7px 50px;
            }
        }
    }
    header.logout{
        .me-4{
            margin-right: 0px !important;
            .text-white{
                padding: 0 !important;
            }
        }
        nav{
            a{
                width: 100%;
                display: block;
                padding: 10px !important; 
                font-size: 15px;
            }
        }
    }
    .post-listing-home{
        & > div{
            padding: 0 12px !important;
        }
    } 

    .btn.view-chat-btn{
        font-size: 14px;
        svg{
            height: 20px;
        }
        &  > div {
            padding: 8px 15px;
        }
    }
    .job-post-head{
        padding: 24px;
        text-align: center;
        h4{
            font-size: 20px;
            span{
                margin-left: 0 !important;
                display: block; 
                margin-top: 10px;
            }
        }
        .post-info{
            justify-content: center !important;
            li{
                small{
                    font-size: 12px;
                }
                div{
                    font-size: 13px;
                }
            }
        }
        .buttons{
            justify-content: center;
        }
        .justify-content-end{
            justify-content: center !important;
        }
    } 
    .inner-title{
        padding: 40px 0;
        h3{
            font-size: 22px;
        }
    }
    .help-links{
        li{
            padding: 10 px 0 !important;
        }
    }
    .padding80{
        padding: 60px 0;
    }
    .nav-tabs{
        .nav-item{
            .nav-link{
                padding: 15px 20px;
            }
        }
    }
    .candidate-profile-head{
        .candidate-info{
            display: block !important;
            text-align: center;
            .user-img{
                margin: 0px auto 25px auto !important;
            }
            .info{
                width: 100%;
                padding: 20px;
                background: #fff;
                border-radius: 8px;
                padding-top: 100px;
                margin-top: -80px;
                ul{
                    li{
                        text-align: left;
                    }
                }
                .progress{
                    background: $grey3;
                }
            }
        }
    }
    .inner-title{
        form{
            padding: 0;
            background-position: 96% 13px;
            background-size: 25px;
        }
    }
    .modal.show{
        .modal-dialog{
            margin-bottom: 80px;
        }
    }
    h2{
        font-size: 25px;
    }
}



// mobile
@media (max-width: 500px) { 
    .chat-single{
        .message{
            .chatbox{
                .suggessions{
                    .scroll{
                        padding: 10px;
                    }
                }
            }
        }
    }
    .grey-btn-rounded-icon{
        width: 30px;
        height: 30px;
    }
    .mobile-filter{ 
        .filter-title{
            h5{
                font-size: 18px;
            }    
        }
        .filter-section{
            h5{
                font-size: 14px;
                font-weight: 500 !important;
                color: #666;
            }
            .mb-4{
                margin-bottom: 10px !important;
            }
        }
    }
    .padding80{
        padding: 40px 0;
    }
    .inner-title{ 
        h3{
            font-size: 20px;
        }
    }
    body{
        font-size: 14px;
    }
    .post-item{
        width: 100%;
    } 
    .mobile-bottomNav{ 
        ul{ 
            a, button{ 
                padding: 0 !important;
                span{
                    display: none;
                }
            }
        } 
    }

    .details-listing{ 
        li{
            display: flex;
            padding: 10px 0;
            div{
                &:first-child{
                    width: 50%;
                    color: #66789C;
                    svg{
                        width: 17px;
                        height: 17px;
                        margin-right: 5px;
                    }
                }
                &:last-child{
                    width: 50%;
                    position: relative;
                    padding-left: 15px;
                    font-weight: 600;
                    &::before{
                        content: ':';
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }
    .job-post-footer{
        display: block;
        ul{
            margin-bottom: 20px;
        }
    } 
    
    .card-grid{
        .card-head{
            .head-btn{
                button{
                    height: 35px;
                    width: 35px;
                    svg{
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
    }
    .candidate-profile-head{
        .info{
            ul{
                column-count: 1;
            }
        }
    }
    .payment-table{
        .status{
            display: none !important;
        }
        .left{
            display: block !important;
            .date{
                margin-bottom: 5px;
            }
        }
    }
    .notifications{
        .noti-body{
            li{
                .icon{
                    min-width: 40px;
                    height: 40px;
                    svg{
                        height: 20px;
                    }
                }
            }
        }
    }
    .help-links{
        svg{
            width: 20px;
        }
    }
    .candidate-profile-head {
        background-color: #F1F2F7;
        padding: 40px 0;
    }
    header.loggedin{
        nav{
            ul{
                .notification, .help{
                    width: 40px;
                    height: 40px;
                }
                .avtar{
                    img{
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        .logo{
            img {
                height: 50px;
            }
        }
    } 
}


@media screen and (min-width: 768px) and (max-width: 1200px) {
    .post-listing-home{
        .post-item{ 
            width: 100%;
        } 
    }
}