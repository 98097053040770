
.ad-slider{
    @include radius8;
    overflow: hidden;
    margin-bottom: 20px;
}

.static-ad{
    @include radius8;
    overflow: hidden;
    margin-bottom: 20px;
}