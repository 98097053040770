

.filter-title{
    border-bottom: 1px solid rgba($color: $grey2, $alpha: 0.5);
    padding: 5px 0 11px 0;
    margin-bottom: 30px;
}
.filter-section{
    h5{
        font-size: 15px;
        font-weight: 700 !important;
    }
    // ::placeholder{
    //     color: ;
    // } 
}