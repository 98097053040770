// list item
.post-item{
    margin-bottom: 20px;
}
.card-grid{
    border: $border;
    padding: 20px;
    height: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    @include radius8;

    .card-head{
        display: flex;
        justify-content: space-between;
        position: relative;
        &.noBtn{
            &::before{
                top: 0 !important;
            }
        }
        .avtar{
            img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                @include radius8;
                margin-bottom: 25px;
            } 
        }
        h3{
            font-size: 18px;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .head-btn{
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            gap: 5px;
            a, button{
                border: none;
                background: $grey3;
                width: 35px;
                height: 35px;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    height: 17px;
                    width: 17px;
                    stroke: $primary;
                    stroke-width: 2px;
                }
                // &:hover{  
                //     path{
                //         fill: $primary !important;
                //     }
                // }
                &.fav.added{
                    path{
                        fill: $primary;
                    }
                }
            }
        } 
    }
    .card-body{
        padding: 0;
        ul{
            padding: 0;
            li{
                padding: 2px 0;
                display: flex;
                div{
                    color: $dark2;
                    width: 50%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    &:last-child{
                        font-weight: 600;
                    }
                }
            }
        }
        .location{
            font-weight: 600;
            margin: 10px 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            span{
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            svg{
                min-width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            & + .location{
                // margin-top: -5px; 
            }
            &.address{
                align-items: flex-start; 
                font-size: 14px;
                line-height: 18px;
                // margin-bottom: 5px;
                span{
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
        button{
            svg{
                height: 20px;    
            }
        }
    }
    &.featured{
        div{
            position: relative;
            z-index: 9;
        }
        .card-head{
            &::before{
                content: 'Featured';
                position: absolute;
                top: 45px; 
                right: 0;
                margin: auto;
                max-width: fit-content;
                text-transform: uppercase;
                border-radius: 20px;
                background: linear-gradient(90deg, #EA4931 0%, #F9AE08 100%);
                padding: 5px 15px;
                color: $white;
                font-weight: 600;
                font-size: 10px;
                letter-spacing: 1px;
            }
        }
        &::before{
            content: '';
            width: 200%;
            height: 200%;
            position: absolute;
            left: 50%;
            top: 50%;
            background: conic-gradient(#32A753, #4185F4, #FBBA03, #EA4732);
            z-index: 0;
            filter: blur(10px);
            -webkit-filter: blur(10px);
            -moz-filter: blur(10px);
            -ms-filter: blur(10px);
            -o-filter: blur(10px);
            @include radius8;
            -webkit-animation: spin 5s linear infinite;
            animation: spin 5s linear infinite;
        }
        &::after{
            content: '';
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            position: absolute;
            top: 3px;
            left: 3px;
            background: $white;
            z-index: 1;
            @include radius4;
        }
    }
    &.job{
        .card-head{
            h3{
                margin-bottom: 2px;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            p{
                font-size: 15px;
                font-weight: 600;
                color: $dark2;
                margin-bottom: 10px;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
    &.job.empanelled{
        .card-head{
            .empanel-check{
                pointer-events: none;
                svg{
                    stroke: none;
                }    
            }
        }
    }
    &:not(.job.empanelled){
        .empanel-check{
            display: none !important;
        }
    }
    &.job.empanelled-paid{ 
        .empanel-paid{
            width: fit-content;
            background-color: transparent;
            pointer-events: none;
            padding-left: 0;
            padding-right: 0;
            img{
                width: 100%;
                max-height: 20px;
            }
        }
    }
    &.job.paid{ 
        .paid-btn{
            width: fit-content;
            background-color: transparent;
            pointer-events: none;
            padding-left: 0;
            padding-right: 0;
            img{
                width: 100%;
                max-height: 20px;
            }
        }
    }
    &:not(.job.empanelled-paid){
        .empanel-paid{
            display: none !important;
        }
    }
    &:not(.favorite){
        .fav{
            display: none !important;
        }
    }
    &:not(.shareBtn){
        .share{
            display: none !important;
        }
    }
    &:not(.delete){
        .deleteBtn{
            display: none !important;
        }
    }
    &:not(.paid){
        .paid-btn{
            display: none !important;
        }
    }
    &:not(.report){
        .report-btn{
            display: none !important;
        }
    }
}

@keyframes spin {
    from {transform: translate(-50%, -50%) rotate(0);}
    to   {transform: translate(-50%, -50%) rotate(360deg);}
}
@-webkit-keyframes spin {
    from {transform: translate(-50%, -50%) rotate(0);}
    to   {transform: translate(-50%, -50%) rotate(360deg);}
}






// listing page - 

.listing-title{
    border-bottom: 1px solid rgba($color: $grey2, $alpha: 0.5);
    padding-bottom: 15px;
    margin-bottom: 30px;
    p{
        font-size: 14px;
        line-height: 22px;
        color: $dark2;
        font-weight: 500;
    }
    .box-border{
        border: 1px solid $grey3;
        display: inline-block;
        padding: 1px 0px 3px 15px;
        vertical-align: sub;
        @include radius4;
        .text-sortby{
            color: $grey2;
            font-weight: 500;
            font-size: 12px;
            display: inline-block;
            white-space: nowrap;
            vertical-align: middle;
            line-height: 29px;
        }
        .dropdown-sort {
            display: flex;
            align-items: center;
            .dropdown-menu.show{
                padding: 0;
                overflow: hidden;
                .dropdown-item{
                    padding: 10px;
                    &.active{
                        background: $primary;
                    }
                }
            }
            .dropdown-toggle{
                padding: 0px !important;
                outline: none;
                font-size: 12px;
                font-weight: 500; 
                display: flex;
                &::after{
                    border: none;
                }
                svg{
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

