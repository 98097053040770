// Mixin
// =====================================================

@mixin radius8{
    border-radius: 8px;
}
@mixin radius4{
    border-radius: 4px;
}
@mixin transition{
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
}
@mixin border-radius{
    border: thin solid #DADBE4;
    border-radius: 4px;
}
@mixin border{
    border: thin solid #DADBE4;
}
@mixin shadow{
    box-shadow: 0 8px 20px 0 rgba($color: $dark, $alpha: 0.1);
}

