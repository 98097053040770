

.notifications{
    .btn-circle{
        background-color: $grey3;
        height: 30px;
        width: 30px;
        border-radius: 30px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            height: 15px;
            stroke: $grey1;
        }
    }
    .noti-body{
        display: block;
        height: 300px;
        overflow: auto;
        &.no-data{
            display: flex;
            align-items: center;
            justify-content: center;
            &::before{
                content: 'No Records Found';
                width: fit-content;
            } 
        }
        li{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500; 
            padding: 10px 0;
            border-bottom: thin solid $grey3;
            position: relative;
            @include transition;
            .content{
                width: 100%;
                a{
                    width: 100%;
                    display: block;
                }
            }
            .icon{
                min-width: 45px;
                height: 45px;
                @include radius8;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: .5rem; 
                @include transition;
                svg{
                    width: 20px !important;
                    height: 20px !important;
                    @include transition;
                }
                &.bg-common{
                    background-color: rgba($color: $secondary, $alpha: 0.05) !important;
                    svg{
                        stroke: $secondary;
                        @include transition;
                    }
                }
                &.bg-success{
                    background-color: #DEFBE6 !important;
                    svg{
                        stroke: #29A71A;
                        @include transition;
                    }
                }
                &.bg-msg{
                    background-color: #EDF5FF !important;
                    svg{
                        stroke: #0344CE;
                        @include transition;
                    }
                }
                &.bg-fail{
                    background-color: #FFF1F1 !important;
                    svg{
                        stroke: #DA1B25;
                        @include transition;
                    }
                }
                &.bg-warning{
                    background-color: #FFF9E1 !important;
                    svg{
                        stroke: #EAB706;
                        @include transition;
                    }
                }
                &.bg-waiting{
                    background-color: #FFEFE2 !important;
                    svg{
                        stroke: #FE7409;
                        @include transition;
                    }
                }
                &.bg-sabka{
                    background-color: rgba($color: $primary, $alpha: 0.05) !important;
                    svg{
                        stroke: none !important;
                        path{
                            fill: $secondary;
                            @include transition;
                        }
                    } 
                }
            }
            .content{
                a{
                    color: $dark2; 
                    @include transition;
                }
            }
            &:hover{
                background-color: $grey3 !important;
                @include transition;
                .content{
                    a{
                        &:hover{
                            color: $primary !important; 
                            @include transition;
                        }
                    }
                }
                .icon{
                    background: rgba($color: $primary, $alpha: 0.1) !important;
                    svg{
                        stroke: $primary;
                    }
                }
                .bg-sabka{
                    .icon{
                        svg{
                            stroke: none !important;
                            path{
                                fill: $secondary !important;
                            }
                        }
                    }
                }
            }
            .close{
                width: 20px;
                height: 20px;
                background: $grey3;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                position: absolute;
                top: 10px;
                right: 1rem;
                z-index: 99;
                cursor: pointer;
                svg{
                    height: 13px;
                    stroke: $dark2;
                }
                &:hover{
                    background-color: $secondary;
                    @include transition;
                    svg{
                        stroke: $white;
                        @include transition;
                    }
                }
            }
        }
    } 
    &.page{
        .noti-body{
            height: 100% !important;
            li{
                .close{
                    top: auto !important;
                }
            }
        }
    }
}
.account-details{
    width: 100%;
    max-width: 280px !important;
    .card{
        border: none !important;
        .card-header{
            border: none;
            h6{
                font-size: 14px;
                margin: 0;
            }
            span{
                font-size: 12px;
                color: $dark2;
            }
        }
        .card-body{
            a{
                font-size: 15px;
                color: $dark2;
                font-weight: 500;
                &:hover{
                    color: $primary; 
                    background-color: $grey3;
                    @include transition;
                    svg{
                        stroke: $primary;
                        @include transition;
                    }
                }
                &:active{
                    background-color: $grey3;
                }
            }
        }
        .card-footer{
            background-color: $white;
            border-top: thin solid $grey2;
            a{
                stroke: $dark2;
                font-size: 15px;
                color: $dark2;
                font-weight: 500;
                &:hover{
                    color: $primary; 
                    @include transition;
                    svg{
                        stroke: $primary;
                        @include transition;
                    }
                }
            }
        }
        svg{
            height: 20px;
            width: 20px;
            stroke: $dark2;
        }
    }
}

.dropdown-menu{
    max-width: 350px;
    border: none;
    color: $dark;
    width: 100%;
}


// pagination

.pagination{
    gap: 5px;
    .page-item{
        .page-link{
            border: none;
            color: $grey1 !important;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
            cursor: pointer;
            &:focus{
                background-color: $white;
            } 
        }
        &.active a{
            color: $white !important;
            background: $gradient;
        }
        &:first-child{
            a{
                background: $grey3;
                &:hover{
                    background: $primary;
                    @include transition;
                    svg{
                        stroke: $white;
                        @include transition;
                    }
                }
            }
        }
        &:last-child{ 
            a{
                background: $grey3;
                &:hover{
                    background: $primary;
                    @include transition;
                    svg{
                        stroke: $white;
                        @include transition;
                    }
                }
            }
        }
    }
    svg{
        height: 20px;
        width: 20px;
    }
}

// grey circle button
.grey-btn-rounded-icon{
    background: $grey3;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: none;
    @include transition;
    svg{
        @include transition;
        height: 20px;  
        stroke: $primary; 
    }
    &:hover{  
        background: $primary;
        @include transition;
        svg{ 
            stroke: $white; 
            @include transition; 
            &.icon-copy path{
                stroke: $white; 
            }
        }
    }
}

// button with icon

.btn-icon{
    display: inline-flex;
    align-items: center;
    svg{
        height: 20px;
    }
}


// btn-secondary
.btn-secondary{
    @include transition;
    &:hover{
        background-color: $primary;
    }
}

// featured rounded btn
.btn-featured{
    padding: 10px 20px;
    background: linear-gradient(90deg, #EA4931 0%, #F9AE08 100%);
    color:$white !important;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: 600;
}

// small title
.title-4{
    font-size: 20px;
    padding: 10px 0;
    border-bottom: thin solid rgba($color: $grey2, $alpha: 0.8) !important;
}


// Accordion
.accordion{
    .accordion-item{
        border: none;
        
        .accordion-button{
            background-color: $grey3;
            border: none !important;
            box-shadow: none !important;
            font-size: 16px;
            margin-bottom: 1px;
            &:focus{
                box-shadow: none !important;
            } 
            &:after{
                background-image: url(../img/plus.svg);
                object-position: center;
            }
            &:not(.collapsed){    
                &::after{
                    background-image: url(../img/minus.svg);
                    object-position: center;
                }
            } 
        }
    } 
}


// under line
.u-line{
    border-bottom: thin solid $grey3;
}

// select gender
.gender-input-group{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    label{
        width: 100%;
        // padding-left: 20px;
        // padding-right: 20px;
        line-height: 48px;
        user-select: none;
        cursor: pointer;
        text-align: center;
        @include border-radius;
        @include transition;
    }
    input{
        display: none;
        &:checked{
            + label{
                color: $white;
                background: $primary;
                border-color: $primary;
                @include transition;
                path{
                    fill: $white;
                    @include transition;
                }
            }
        }
    }
    & > div{
        .invalid-feedback{
            display: none;
        }
        &:first-child{
            .invalid-feedback{
                display: block;
                white-space: nowrap;
            }
        }
    }
}

// dob

.dob{
    display: flex;
    span{
        background: $grey3;
        line-height: 50px;
        min-width: 50px;
        height: 50px;
        @include radius4;
        margin-left: 10px;
        display: inline-block;
        text-align: center;
        font-weight: 800;
        color: $dark2;
    }
    input{
        appearance: none;
    }
}

// slry switch
.salary-switch{
    input{
        &:checked + label{
            &::before{
                left: 18px;
                @include transition;
            }
        }
        & + label{
            width: 40px;
            height: 20px;
            margin: 0 5px;
            border-radius: 20px;
            border: 1px solid $grey2;
            display: inline-block;
            position: relative; 
            &::before{
                content: '';
                width: 17px;
                height: 14px;
                background-color:#40C375 ;
                display: inline-block;
                @include radius8;
                position: absolute;
                top: 2px;
                left: 3px;
                @include transition;
            }
        }
    } 
}


// how to contact - form input

.how-contact{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    & > div{
        position: relative;
        width: 50%;
    }
    img{
        height: 40px;
        border-radius: 8px;
    }
    label{
        @include border-radius;
        padding: 16px;
        display: block;
        cursor: pointer;
        height: 100%;
        &:last-child{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            padding: 0;
        }
    }
    input{
        &:checked{
            & + label{
                background-color: $grey3;
            }
            & + label + label{
                &::before{
                    content: "";
                    border-left: 3px solid #4F5E64;
                    border-bottom: 3px solid #4F5E64;
                    width: 14px;
                    height: 8px;
                    display: inline-block;
                    border-radius: 0 0 0 4px;
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 4px;
                    right: 2px;
                }
            }
        }
    }
}

.profile-pic{
    label{
        text-align: center;
        margin-bottom: 20px;
        margin: auto;
        display: block;
        cursor: pointer;
        width: fit-content; 
        position: relative;
        img{
            max-width: 130px;  
        }
        .icon{
            width: 35px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -15px; 
        }
    } 
}

.toast{
    border: none !important;
}

.inner-title{
    padding: 60px 0;
    background-color: rgba($color: $primary, $alpha: 0.1);
    display: flex;
    align-items: center;
    form{
        padding: 10px ;
        background-color: $white;
        @include radius8;
        background-image: url(../img/search-icon.svg);
        background-repeat: no-repeat;
        background-position: 96% 20px;
        background-size: 30px;
        input{
            border: none !important;
            background: transparent !important;
        }
    }
    h3{
        font-size: 28px;
        font-weight: 700;
    }
    &.help{
        // min-height: 200px; 
    }
}


// Overlay
.overlay{
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: $dark, $alpha: 0.3);
    z-index: 99999; 
    opacity: 0;
    pointer-events: none;
    @include transition;
}
.show-overlay{
    .overlay{ 
        width: 100%;
        opacity: 1;
        pointer-events: all; 
        @include transition;
    }
}

// star list
.star-list{
    margin: 0;
    padding: 0;
    li{
        padding: 5px 0 5px 30px;
        background-image: url(../img/star.svg);
        background-repeat: no-repeat;
        background-position: 0 7px;
        color: $dark2;
    }
}

// sub menu
.has-children{
    position: relative;
    &:hover{
        .sub-menu{
            top: 35px; 
            opacity: 1;
            visibility: visible;
            @include transition;
        }    
    }
    .sub-menu{
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.2s;
        position: absolute;
        top: 60px;
        left: 0px;
        z-index: 999;
        min-width: 220px;
        @include radius8;
        border: 1px solid $grey2;
        box-shadow: 0px 10px 20px -5px rgba(10, 42, 105, 0.06);
        background-color: #ffffff;
        padding: 10px 0px;
        display: block;
        @include transition;
        li{ 
            padding: 0 !important;
            a{
                padding: 5px 15px;
                color: $dark !important;
                font-size: 16px !important;
                &:hover{
                    color: $primary !important;
                    background-color: $grey3;
                }
            }
        }
    }
}

// tabs
.profile{
    .nav-tabs{ 
        .nav-item{ 
            .nav-link{    
                &:hover{ 
                    padding-left: 40px !important; 
                }
            }
        }
    }
}
.nav-tabs{
    &::before{
        height: 0;
    }
    .nav-item{
        padding: 0 !important;
        border-bottom: thin solid $grey3;
        .nav-link{
            color: $dark;
            padding: 15px 50px; 
            font-weight: 500 !important;
            // border: thin solid $grey3;
            margin-bottom: 0 !important;
            background-color: $white;
            border-bottom: thin solid $grey3;
            margin-right: 1px;
            position: relative;
            @include transition;
            border: none;
            &::after{
                display: none;
            } 
            &.active{ 
                background: $gradient;
                color: $white !important;
                &:hover{
                    background: $gradient;
                    color: $white !important;
                }
            }
            &:hover{
                background: $grey3; 
                @include transition;
                color: $primary !important;
            }
        }
    }
}

.cropper-container{
    width: 100% !important;
    // .cropper-canvas {
    //     width: 100% !important;
    //     height: 100% !important;
    //     transform: translateY(0px) !important;
    //     img{
    //         width: 100% !important;
    //         height: 100% !important;
    //     }
    // }
}

.list-exclamation{
    padding: 5px 0;
    li{
        padding: 5px 5px 5px 30px;
        position: relative;
        &::before{
            content: '!';
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: $secondary;
            position: absolute;
            left: 0;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.form-check{
    position: relative;
    .form-check-input{
        display: none;
        &:checked{
            &+ label{
                &::before{
                    background-color: $primary;
                }
                &::after{
                    content: '';
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    border-radius: 10px;
                    background-color: $white; 
                }
            }
        }
    }
    .form-check-label{
        padding-left: 5px;
        &::before{
            content: '';
            width: 20px;
            height: 20px;
            background-color: $grey3;
            display: inline-block;
            position: absolute;
            left: 0;
            border-radius: 10px;
        }
    }
}


// slry switch
.switch{
    input{
        &.active-check{
            + label{
                &::before{
                    left: 18px;
                    background-color:$green;
                    @include transition;
                }
            }
        }
        &:checked + label{
            &::before{
                left: 18px;
                background-color:$green;
                @include transition;
            }
        }
        & + label{
            width: 40px;
            height: 20px;
            margin: 0 5px;
            border-radius: 20px;
            border: 1px solid $grey2;
            display: inline-block;
            position: relative; 
            &::before{
                content: '';
                width: 17px;
                height: 14px;
                background-color:$grey2;
                display: inline-block;
                @include radius8;
                position: absolute;
                top: 2px;
                left: 3px;
                @include transition;
            }
        }
    } 
    label{
        cursor: pointer;
    }
}

.btn-more{
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: $grey3;
    @include transition;
    svg{
        stroke: $dark2;
        height: 20px;
    }
    &:hover{
        background-color: $red;
        @include transition;
        svg{
            stroke: $white;
            @include transition;
        }
    }
}

.form-check-label{
    font-size: 14px !important;
}
.checkbox-group{
    label{
        position: relative;
        padding-left: 20px;
        width: 20px;
        height: 20px;
        margin: 0;
        font-size: 14px !important;
        cursor: pointer;
        &::before{
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 4px;
            border:thin solid $grey2;
            position: absolute;
            left: 0;
            @include transition;
        }
        &::after{
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 4px;
            position: absolute;
            left: 4px;
            top: 4px;
            @include transition;
        }
    }
    input{ 
        &:checked{
            & + label{
                &::before{
                    content: '';
                    border-color: $primary;
                    @include transition;
                }
                &::after{
                    background-color: $primary;
                    @include transition;
                }
            }
        }
    }
}

.testimonial-slider{
    margin-bottom: 0 !important;
    padding-top: 20px;
    .item{
        margin: 0 10px; 
        .card-text{
            border: 1px solid rgba($color: $grey2, $alpha: 0.7);
            padding: 20px;
            border-radius: 8px;
            position: relative;
            margin-bottom: 35px;
            background-color: $white;
            &::before{
                content: "";
                height: 20px;
                width: 20px;
                bottom: -11px;
                left: 20px;
                position: absolute;
                z-index: 2;
                border-right: thin solid rgba($color: $grey2, $alpha: 0.7);
                border-bottom: thin solid rgba($color: $grey2, $alpha: 0.7);
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                background-color: $white;
            }
        }
        .card-image{
            display: flex;
            padding-left: 5px;
            align-items: center;
            gap: 10px; 
            @include transition;
            .image{
                width: 65px;
            }
            .card-profile{
                width: 100%;
                padding-top: 5px;
            }
        }
        &:hover{
            .card-image{
                margin-top: -15px;
                @include transition;
            }
        }
    }
    .slick-dots{
        position: initial;
        margin-top: 30px;
        li{ 
            button{
                &::before{
                    font-size: 50px;
                    padding: 0;
                    width: initial;
                    height: initial;
                    color: $primary;
                }
            }
            &.slick-active{
                button{
                    &::before{
                        opacity: 1;
                    }
                }
            }
        }
    } 
}

address{
    p{
        margin-bottom: 5px;
        font-weight: 600;
    }
}

.alert{ 
    border: none !important;
    h6{
        font-weight: 400 !important; 
        font-size: 14px;
        color: $white !important;
        a{
            color: $white !important;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }
    &.alert-warning{
        background-color: $orange;
    }
    &.alert-danger{
        background-color: #E20000;
    }
}

.number-list{
    padding: 0 22px !important;
    margin: 0;
    li{
        padding:5px 15px;
        position: relative;
        &::after{
            content: "";
            background-color: #741f78;
            width: 25px;
            height: 25px;
            display: inline-block;
            position: absolute;
            left: -23px;
            border-radius: 20px;
            z-index: 0;
            opacity: 0.1;
            background-blend-mode: multiply;
        }
        span{
            position: relative;
            z-index: 9;
        }
    } 
}
